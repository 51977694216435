import React from 'react'
// import Logo from '../asset/icons/LogoSm.svg';
import { Div, Img, Button, Flex, } from '../style/StyledElements';
import ChatClose from './ChatClose.svg'
import UserDetailsForm from './UserDetailsForm';
import BotAvatar from './BotAvatar';

function ChatHeader(props) {
  const { closeChat = () => { } } = props
  return (
    <Flex >
      <Flex
        width='100%'
        // alignItems="center"
        borderBottom='1px solid rgba(255, 255, 255, 0.07)'
        paddingBottom='18px'
        paddingTop={{ xs: '0%', md: '0' }}
      >
        <Div>
          <Div
            color='#FFF'
            fontSize='24.68px'
            fontWeight='500'
            paddingBottom='8px'
            paddingTop={{ xs: '5%', md: '0' }}
          >

            Bubble Synth
          </Div>
          <Div color='rgba(255, 255, 255, 0.40)' font-size='16px' fontWeight='400'>Understanding starts with a chat</Div>
        </Div>
        <Div ml="auto">
          <Button
            position='absolute'
            right='4'
            borderRadius='70px'
            background='rgba(255, 255, 255, 0.19)'
            boxShadow='0px 4px 18px 0px rgba(0, 0, 0, 0.25)'
            padding='7px'
            onClick={() => closeChat()}
          >
            <Img
              width="14px"
              src={ChatClose}
              style={{ display: 'block', margin: 'none' }}
            />
          </Button>
        </Div>

      </Flex>
      <Div className='user-form-container'>
        <Div className='user' position='relative' >
          <UserDetailsForm />
       
        </Div>
        <Div
            display={{ xs: 'none', md: 'block' }}
            border=' 1px solid #3c4f8d'
            borderRadius='50px'
            height='40px'
            width='40px'
            background='#fff'
            // position='absolute'
            overflow='hidden'
            left='-38px'
            bottom='-40px'
          >
            <BotAvatar />
          </Div>
      </Div>

    </Flex>
  )
}

export default ChatHeader