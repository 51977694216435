import { createChatBotMessage } from 'react-chatbot-kit';
import React from 'react';
import BotAvatar from './BotAvatar';
import UserDetailsForm from './UserDetailsForm';

const config = {
  // initialMessages: [createChatBotMessage(<UserDetailsForm />)],
  initialMessages: [createChatBotMessage("Welcome to our website 👋Nice to meet you! If you have any questions about our company, feel free to ask.")],
  botName: "RoadeChatBot",
  customComponents: {
    botAvatar: (props) => <BotAvatar {...props} />,
  },
};

export default config;