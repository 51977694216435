import React from 'react'
// import Logo from '../asset/icons/LogoSm.svg';
import Avatar from './chatAvtr.svg';
import AvatarIos from './BotGirlimg.png';
import { Div, Img } from '../style/StyledElements';

const getOS = () => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  // Check if the user agent contains 'Android' or 'iOS'
  if (/android/i.test(userAgent)) {
    return 'Android';
  }

  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return 'iOS';
  }

  return 'Unknown';
};

function BotAvatar() {
  return (
    <Div><Img className='avatar' src={getOS() === 'Android' ? Avatar : AvatarIos} /></Div>
  )
}

export default BotAvatar