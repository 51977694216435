import React, { useEffect, useState } from 'react';
import Chatbot from 'react-chatbot-kit';
import 'react-chatbot-kit/build/main.css';
import './Chat.css';
import config from './config';
import ActionProvider from './ActionProvider';
import MessageParser from './MessageParser';
import { Div, } from '../style/StyledElements';
import Bub from './BotImg.svg';
import { useSpring, animated } from '@react-spring/web'
import ChatHeader from './ChatHeader';
import Storage from './Storage';

function Chat(props) {

    const [showBot, toggleBot] = useState(false);
    const [showDiv, setShowDiv] = useState(false);
    const [chatOpen, chatOpened] = useState(false);
    const [isChatOpened, setIsChatOpened] = useState(() => {
        const storedState = Storage.getItem('chatOpened', false);
        return storedState;
    });

    useEffect(() => {
        if (isChatOpened) {
            handleChatOpen();
        }
    }, [isChatOpened]);

    const getOS = () => {
        const userAgent = navigator.userAgent || navigator.vendor || window.opera;

        // Check if the user agent contains 'Android' or 'iOS'
        if (/android/i.test(userAgent)) {
            return 'Android';
        }

        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            return 'iOS';
        }

        return 'Unknown';
    };

    const resizeOps = () => {
        document.documentElement.style.setProperty("--vh", window.innerHeight * 0.01 + "px");
    }



    useEffect(() => {
        const os = getOS();
        resizeOps();

        // window.addEventListener("scroll", resizeOps);
        window.addEventListener("resize", resizeOps);
        if (os === 'iOS') {
            document.addEventListener('touchmove', function (event) {
                if (event.scale !== 1) {
                    event.preventDefault();
                }
            }, { passive: false });
        }
        return () => {

        }
    }, [])

    const saveMessages = (messages, HTMLString) => {
        Storage.setItem('chat_messages', JSON.stringify(messages), false);
    };

    const loadMessages = () => {
        const messages = JSON.parse(Storage.getItem('chat_messages', false));
        return messages;
    };

    const validateInput = (value) => {
        if (value)
            return true;
    }
    const os = getOS();
    //main window
    const [springs, api] = useSpring(() => ({
        from: { x: 500, y: 500, opacity: 0 },
        config: { duration: os === 'iOS' ? 1300 : 1000 },
    }))
    //Bot Icon
    const [springs2, api2] = useSpring(() => ({
        from: { x: 0, y: 0, opacity: 1 },
        config: { duration: window.innerWidth <= 768 ? 1000 : 500 }
    }))

    const handleChatOpen = () => {
        api.start({
            from: {
                x: 500,
                y: window.innerWidth <= 768 ? 1000 : 500,
                opacity: 0,
            },
            to: {
                x: 0,
                y: 0,
                opacity: 1,
            },
        });
        api2.start({
            from: {
                y: 0,
                x: 0,
                opacity: 1,
            },
            to: {
                // y: window.innerWidth <= 768 ? 180 : 60,
                // x: window.innerWidth <= 768 ? 180 : 60,
                x: 180,
                y: 180,
                opacity: 1,
            },
        });
        setTimeout(() => {
            setShowDiv(true);
            document.body.classList.add("no-scroll");
        }, window.innerWidth <= 768 ? 1500 : 1200);
        setTimeout(() => {
            document.body.classList.add("no-scroll");
        }, 1200);
        toggleBot(true);
        Storage.setItem('chatOpened', true, false);
        chatOpened(true);
    }
    const handleChatClose = () => {
        document.body.classList.remove("no-scroll")
        setShowDiv(false);
        api.start({
            from: {
                opacity: 1,
            },
            to: {
                x: 500,
                y: window.innerWidth <= 768 ? 1000 : 500,
                opacity: 0,
            },
        });
        api2.start({
            from: {
                y: 100,
                x: 100,
                opacity: 1,
            },
            to: {
                y: 0,
                x: 0,
                opacity: 1,
            },
        });
        setTimeout(() => {
            toggleBot(false);
        }, 500);

        Storage.setItem('chatOpened', false, false);
        chatOpened(false);
    }

    return (
        <>

            <animated.div

                className={'chat-main-window'}
                style={{
                    paddingTop: 10,
                    paddingBottom: 10,
                    paddingLeft: os == 'iOS' ? 0 : 10,
                    paddingRight: os == 'iOS' ? 0 : 10,
                    position: 'fixed',
                    zIndex: 10002,
                    ...springs,
                }}>
                <Div position='relative' bg='rgb(0 0 0 / 50%)' borderRadius='30px'>

                    {(os !== 'iOS') ? (
                        showDiv && (
                            <>
                                <Div
                                    display={{ xs: 'none', md: 'block' }}
                                    position='absolute'
                                    width='1px'
                                    height='50px'
                                    left='0'
                                    bg='rgba(0, 186, 52, 1)'
                                    top='25%'
                                />
                                <Div
                                    top={{ md: '25%', xs: '6' }}
                                    position='absolute'
                                    width='8%'
                                    height='100px'
                                    bg='rgba(0, 186, 52, 1)'
                                    right='0'
                                />


                                <Div
                                    display={{ md: 'block', xs: 'none' }}
                                    position='absolute'
                                    width='100px'
                                    height='1px'
                                    bg='#FFF'
                                    top='0'
                                    right='40%'
                                />
                                <Div
                                    bottom='1px'
                                    width='150px'
                                    height='40px'
                                    right='30%'
                                    position='absolute'
                                    style={{
                                        background: 'linear-gradient(143deg, rgb(97, 0, 255) 8.37%, rgba(0, 255, 255, 0) 91.49%), linear-gradient(143deg, rgb(97, 0, 255) 8.37%, rgba(0, 255, 255, 0) 91.49%)',
                                    }}
                                />

                            </>
                        )
                    ) :
                        <>
                            <Div
                                style={{
                                    position: 'absolute',
                                    top: '25%',
                                    width: '20%',
                                    height: '100px',
                                    background: 'rgba(0, 186, 52, 1)',
                                    filter: 'blur(60px)',
                                }}
                                borderRadius='50%'
                                display={{ xs: 'none', md: 'block' }}
                            />
                            <Div
                                top={{ md: '25%', xs: '5' }}
                                width={{ xs: '20%', md: '30%' }}
                                style={{
                                    position: 'absolute',
                                    right: 0,
                                    height: '100px',
                                    background: 'rgba(0, 186, 52, 1)',
                                    filter: 'blur(60px)',
                                }}
                                borderRadius='50%'
                            />
                            <Div
                                display={{ xs: 'none', md: 'block' }}
                                style={{
                                    position: 'absolute',
                                    top: 1,
                                    right: '40%',
                                    width: '100px',
                                    height: '10%',
                                    background: '#FFF',
                                }}
                                borderRadius='50%'
                            />
                            <Div
                                style={{
                                    position: 'absolute',
                                    bottom: '0',
                                    right: '40%',
                                    width: '100px',
                                    height: '15%',
                                    background: 'linear-gradient(143deg, rgb(97, 0, 255) 8.37%, rgba(0, 255, 255, 0) 91.49%), linear-gradient(143deg, rgb(97, 0, 255) 8.37%, rgba(0, 255, 255, 0) 91.49%)',
                                }}
                                borderRadius='50%'
                            />

                        </>
                    }
                    {showBot &&
                        <Chatbot
                            placeholderText={'Type your message'}
                            validator={validateInput}
                            headerText={<ChatHeader closeChat={handleChatClose} />}
                            config={config}
                            actionProvider={ActionProvider}
                            messageHistory={loadMessages()}
                            messageParser={MessageParser}
                            saveMessages={saveMessages}
                        />
                    }
                </Div>
            </animated.div>

            <animated.div
                style={{
                    position: 'fixed',
                    bottom: 0,
                    right: window.innerWidth <= 768 ? -90 : -40,
                    zIndex: 1000,
                    ...springs2,
                    cursor: 'pointer'
                }} >
                <Div
                    // onClick={!chatOpen ? handleChatOpen : handleChatClose}
                    onClick={!chatOpen && handleChatOpen}
                    width={{ xs: '50%', md: '70%' }}
                >
                    <img src={Bub} alt="Bot Bubble Icon" style={{ maxWidth: '100%', height: 'auto' }} />
                </Div>
            </animated.div>
        </>
    );
}

export default Chat;


