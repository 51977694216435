import ls from 'localstorage-slim';

class Storage {

    setItem = (key, value, encrypt = true) => {
        // return localStorage.setItem(key, value);
        return ls.set(key, value, { encrypt: false });
    }
    getItem = (key, encrypt = true) => {
        // return localStorage.getItem(key);
        return ls.get(key, { encrypt: false })
    }
    removeItem = (key) => {
        return ls.remove(key)
    }

}

export default new Storage();