import React, { useState, useEffect } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Div, Flex, Button, Span, Para } from '../style/StyledElements';
import axios from 'axios';
import PhoneInputWithCountry, {
    isValidPhoneNumber,
    formatPhoneNumberIntl,
} from 'react-phone-number-input';
import './phoneNumberInput.css';
import 'react-phone-number-input/style.css';
import Storage from './Storage';

const validationSchema = Yup.object().shape({
    name: Yup.string()
        .matches(/^[a-zA-Z\s ]+$/, 'Please provide a valid name')
        .required('Name is required')
        .trim(),
    phone: Yup.string()
        .test(
            'isValidPhoneNumber',
            'Invalid phone number format in the selected country',
            (value) => {
                return isValidPhoneNumber(value);
            }
        )
        .required('Phone number is required'),
    // email: Yup.string().email('Invalid email address').required('Email is required'),
    email: Yup.string()
        .matches(
            /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/,
            'Invalid email address'
        )
        .required('Email is required')
        .trim(),
});

function UserDetailsForm() {
    const [submitted, setSubmitted] = useState(false);

    useEffect(() => {
        const user = JSON.parse(Storage.getItem('userData'));
        if (user) {
            setSubmitted(true);
            document.body.classList.add('view');
            document.body.classList.add('removeForm');
            document.body.classList.add('close');
            document.body.classList.add('open');
            document.body.classList.add('openButton');
            document.body.classList.add('closePlaceholder');
        }
    }, []);

    const handleSubmit = async (values, { resetForm }) => {
        try {
            // console.log(values);
            const updated_values = {
                ...values,
                name: values.name.trim(),
            };
            await axios
                .post(
                    'https://verdictbook.in/api/contact/save',
                    updated_values,
                    {
                        headers: {
                            'X-API-Key': '5b8e7e84-7011-4c47-a5f2-d02ab153de29',
                        },
                    }
                )
                .then((response) => {
                    const id = response.data?.id;

                    const userDataWithId = {
                        ...updated_values,
                        id: id,
                    };
                    Storage.setItem('userData', JSON.stringify(userDataWithId));
                });

            resetForm();
            setSubmitted(true);
            document.body.classList.add('view');
            document.body.classList.add('removeForm');
            document.body.classList.add('close');
            document.body.classList.add('open');
            document.body.classList.add('openButton');
            document.body.classList.add('closePlaceholder');
        } catch (error) {
            console.error('Error submitting form:', error);
        }
    };

    const initialFormValues = { name: '', phone: '', email: '' };

    return (
        <Flex className='user-details-form'>
            <Div paddingBottom='14px'>
                <Span {...styles.welcomeFont}>Welcome to our website 👋</Span>
                <Para {...styles.paraFont}>
                    Nice to meet you! If you have any questions about our
                    company,{' '}
                    {!submitted
                        ? 'kindly enter your details.'
                        : 'feel free to ask.'}
                </Para>
            </Div>
            <Div width='100%'>
                {!submitted && (
                    <Formik
                        initialValues={initialFormValues}
                        validationSchema={validationSchema}
                        onSubmit={handleSubmit}
                    >
                        {({ setFieldValue }) => (
                            <Form>
                                <Div paddingBottom={{ xs: '25px', md: '10px' }}>
                                    <Field
                                        name='name'
                                        placeholder='Name'
                                        type='text'
                                        style={styles.input}
                                    />
                                    <ErrorMessage
                                        name='name'
                                        component='div'
                                        style={{
                                            color: 'red',
                                            'font-size': '10px',
                                        }}
                                    />
                                </Div>
                                <Div paddingBottom={{ xs: '25px', md: '10px' }}>
                                    <PhoneInputWithCountry
                                        className='chat-phone-input'
                                        name='phone'
                                        placeholder='Phone Number'
                                        style={styles.input}
                                        onChange={(value) =>
                                            setFieldValue(
                                                'phone',
                                                formatPhoneNumberIntl(value)
                                            )
                                        }
                                        international={true}
                                        countryCallingCodeEditable={false}
                                        smartCaret={false}
                                        defaultCountry={'IN'}
                                    />
                                    <ErrorMessage
                                        name='phone'
                                        component='div'
                                        style={{
                                            color: 'red',
                                            'font-size': '10px',
                                        }}
                                    />
                                </Div>
                                <Div paddingBottom={{ xs: '25px', md: '10px' }}>
                                    <Field
                                        name='email'
                                        placeholder='E-mail'
                                        type='email'
                                        style={styles.input}
                                    />
                                    <ErrorMessage
                                        name='email'
                                        component='div'
                                        style={{
                                            color: 'red',
                                            'font-size': '10px',
                                        }}
                                    />
                                </Div>
                                <Div>
                                    <Button
                                        bg='#FFF'
                                        height='50px'
                                        type='submit'
                                        width='100%'
                                        color='#733C80'
                                        marginTop='10px'
                                    >
                                        Enter the chat
                                    </Button>
                                </Div>
                            </Form>
                        )}
                    </Formik>
                )}
            </Div>
        </Flex>
    );
}

export default UserDetailsForm;
const styles = {
    input: {
        background: 'transparent',
        border: 'none',
        borderBottom: '1px solid rgba(232, 232, 232, 0.43)',
        borderRadius: '0',
        padding: '0',
        paddingBottom: '5px',
        paddingRight: '12px',
        color: '#FFF',
        fontSize: '14px',
        fontWeight: '400',
        width: '100%',
        outline: 'none',
    },
    welcomeFont: {
        color: '#E8E8E8',
        fontSize: '15px',
        fontWeight: '700',
        lineHeight: '18px',
        paddingBottom: '9px',
    },
    paraFont: {
        color: '#FFF',
        fontSize: '14px',
        fontWeight: '400',
        lineHeight: '22px',
        marginTop: '10px',
    },
};
