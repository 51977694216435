import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { ThemeProvider } from 'styled-components';
import theme from './style/theme';
import { BrowserRouter } from 'react-router-dom';
// import 'animate.css';
// import Scroll from './components/Scroll';
// import { UseEffectScroll } from 'react-use-smooth-scroll';
// import 'react-use-smooth-scroll/dist/index.css';
import { lazy } from 'react';
import { Suspense } from 'react';
import Loader from './components/loader/Loader';
import Chat from './chat/Chat';
const Routing = lazy(() => import('./Routing'));

const root = ReactDOM.createRoot(document.getElementById('root'));
const isProductionENv = process.env.REACT_APP_ENV === 'development';
console.log(process.env.REACT_APP_ENV);
root.render(
    <React.StrictMode>
        <ThemeProvider theme={theme}>
            <BrowserRouter>
                <Suspense fallback={<Loader />}>
                    <Routing />
                    {!isProductionENv && <Chat />}
                </Suspense>
            </BrowserRouter>
        </ThemeProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
