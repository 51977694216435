import axios from 'axios';
import React from 'react';
import Storage from './Storage';

const ActionProvider = ({ createChatBotMessage, setState, children }) => {
    const handleHello = async (message) => {
        const user = JSON.parse(Storage.getItem('userData'));
        const clientId = user.id;
        const clientName = user.name;
        await axios
            .post(
                'https://verdictbook.in/api/chat/',
                { query: message, id: clientId, name: clientName },
                {
                    headers: {
                        'X-API-Key': '5b8e7e84-7011-4c47-a5f2-d02ab153de29',
                    },
                }
            )
            .then((response) => {
                const botMessage = createChatBotMessage(response.data?.answer);
                setState((prev) => ({
                    ...prev,
                    messages: [...prev.messages, botMessage],
                }));
            });
    };

    return (
        <div>
            {React.Children.map(children, (child) => {
                return React.cloneElement(child, {
                    actions: {
                        handleHello,
                    },
                });
            })}
        </div>
    );
};

export default ActionProvider;
